@import "~bootstrap/scss/bootstrap";

body {
    margin: 0.5rem 0.5rem 0 0.5rem /* some space around the edges */;
    /* bottom padding makes space for the fixed navbar */
    padding-bottom: $nav-link-height + $nav-link-padding-y * 2; }

.navbar {
    border-top: 1px solid #888;
    margin-bottom: 0;
    margin-top: $nav-link-padding-y;
    padding-bottom: 0;
    padding-top: 1px; }
.navbar-brand {
    margin-right: 0; }
.nav-link {
    color: #000;
    display: inline;
    img {
        display: inline-block;
        height: 1rem;
        margin-right: 0.1rem;
        position: relative;
        top: -1px;
        width: 1rem; } }
.nav-link.active {
    background-color: #CFF; }

.full-screen {
    position: absolute;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw; }

.splash-screen {
    @extend .full-screen;
    background-color: #DDF;
    text-align: center;
    h1 {
        margin-top: 15vh;
        margin-bottom: 10vh; }
    .spinner-border-lg {
        height: 50vw;
        width: 50vw; } }

.blocking-error {
    @extend .full-screen;
    background-color: #FDD;
    padding: 0.5rem;
    .error {
        margin-top: 1rem; } }

.load-save-indicator {
    margin-right: 0.2rem;
    .spinner-border {
        opacity: 0.25; }
    .save-error {
        color: #F00;
        font-weight: bold; } }

.logo-and-name {
    img {
        height: 50px;
        width: 50px; }
    .name {
        margin-left: 5px;
        position: relative;
        top: 6px;
        color: #0090C5;
        font-family: Helvetica;
        font-weight: bold;
        @media screen and ( min-width: 400px ) {
            font-size: 2.5rem; }
        @media screen and ( min-width: 374px ) and ( max-width: 400px ) {
            font-size: 2.3rem; }
        @media screen and ( min-width: 335px ) and ( max-width: 374px ) {
            font-size: 2rem; }
        @media screen and ( min-width: 295px ) and ( max-width: 335px ) {
            font-size: 1.7rem; }
        @media screen and ( min-width: 240px ) and ( max-width: 295px ) {
            font-size: 1.3rem; }
        @media screen and ( max-width: 240px ) {
            font-size: 1rem; } } }

.about {
    max-width: 800px;
    .name {
        color: #0090C5;
        font-weight: bold; } }
